const env = process.env.REACT_APP_ENV;

/* Applied only to local environment */
const localAuthConfig: any = {
  awsRegion: "us-east-2",
  cognitoRegion: "us-east-2",
  userPoolWebClientId: "4aiokj82e5js1n0gkceubct4h9",
  domain: "dev-experience-platform-nutrien.auth.us-east-2.amazoncognito.com",
  appUserGroupName: "dxp-orchestrator-user",
  scope: [
    "phone",
    "email",
    "profile",
    "openid",
    "aws.cognito.signin.user.admin"
  ],
  redirectSignIn: "http://localhost:3000/",
  redirectSignOut: "http://localhost:3000/",
  userPoolId: "us-east-2_dP8bBMlb6",
  appClientId: "4aiokj82e5js1n0gkceubct4h9",
  loglevel: "DEBUG",
  authEndpoint: "authorizeWarehouseUser",
  cookieExpiration: 7,
  warehouse: {
    url: "https://devapi.nx.nutrien.com/warehouse/",
    endpoint: "authorizeWarehouseUser",
    appCode: "dxp-warehouse",
    approvedUserGroups:
      "dxpw-admin,Warehouse Administrator,dxpw-standard,Warehouse Operator,dxpw-standard-read-only,Warehouse OperatorReadonly,Warehouse OperatorCanReceive,dxpw-coordinator,Warehouse Coordinator,dxpw-coordinator-read-only,Warehouse CoordinatorReadonly"
  },
  sales: {
    url: "https://devapi.nx.nutrien.com/sales/",
    endpoint: "authorizeUser",
    appCode: "dxp-salesContract",
    approvedUserGroups:
      "dxp-salesContract-user,dxp-salesManager-group,dxp-salesDirector-group"
  },
  railcar: {
    url: "https://devapi.nx.nutrien.com/railfleetmanagement/",
    endpoint: "authorizeUser",
    appCode: "dxp-salesContract",
    approvedUserGroups: "dxp-rfm-admin,dxp-rfm-analyst,dxp-rfm-viewer"
  }
};

const devAuthConfig: any = {
  awsRegion: "us-east-2",
  cognitoRegion: "us-east-2",
  userPoolWebClientId: "4aiokj82e5js1n0gkceubct4h9",
  domain: "dev-experience-platform-nutrien.auth.us-east-2.amazoncognito.com",
  appUserGroupName: "dxp-orchestrator-user",
  scope: [
    "phone",
    "email",
    "profile",
    "openid",
    "aws.cognito.signin.user.admin"
  ],
  redirectSignIn: "https://dev.nx.nutrien.com/",
  redirectSignOut: "https://dev.nx.nutrien.com/",
  userPoolId: "us-east-2_dP8bBMlb6",
  appClientId: "4aiokj82e5js1n0gkceubct4h9",
  loglevel: "DEBUG",
  authEndpoint: "authorizeWarehouseUser",
  cookieExpiration: 7,
  warehouse: {
    url: "https://devapi.nx.nutrien.com/warehouse/",
    endpoint: "authorizeWarehouseUser",
    appCode: "dxp-warehouse",
    approvedUserGroups:
      "dxpw-admin,Warehouse Administrator,dxpw-standard,Warehouse Operator,dxpw-standard-read-only,Warehouse OperatorReadonly,Warehouse OperatorCanReceive,dxpw-coordinator,Warehouse Coordinator,dxpw-coordinator-read-only,Warehouse CoordinatorReadonly"
  },
  sales: {
    url: "https://devapi.nx.nutrien.com/sales/",
    endpoint: "authorizeUser",
    appCode: "dxp-salesContract",
    approvedUserGroups:
      "dxp-salesContract-user,dxp-salesManager-group,dxp-salesDirector-group"
  },
  railcar: {
    url: "https://devapi.nx.nutrien.com/railfleetmanagement/",
    endpoint: "authorizeUser",
    appCode: "dxp-salesContract",
    approvedUserGroups: "dxp-rfm-admin,dxp-rfm-analyst,dxp-rfm-viewer"
  }
};
const stagingAuthConfig: any = {
  awsRegion: "us-east-2",
  cognitoRegion: "us-east-2",
  userPoolWebClientId: "1p1h21bq988pcui1l1b37ejcc5",
  domain: "stage-experience-platform-nutrien.auth.us-east-2.amazoncognito.com",
  appUserGroupName: "dxp-orchestrator-user",
  scope: [
    "phone",
    "email",
    "profile",
    "openid",
    "aws.cognito.signin.user.admin"
  ],
  redirectSignIn: "https://stage.nx.nutrien.com/",
  redirectSignOut: "https://stage.nx.nutrien.com/",
  userPoolId: "us-east-2_l685tImU4",
  appClientId: "1p1h21bq988pcui1l1b37ejcc5",
  loglevel: "DEBUG",
  authEndpoint: "authorizeWarehouseUser",
  cookieExpiration: 7,
  warehouse: {
    url: "https://stagingapi.nx.nutrien.com/warehouse/",
    endpoint: "authorizeWarehouseUser",
    appCode: "dxp-warehouse",
    approvedUserGroups:
      "dxpw-admin,Warehouse Administrator,dxpw-standard,Warehouse Operator,dxpw-standard-read-only,Warehouse OperatorReadonly,Warehouse OperatorCanReceive,dxpw-coordinator,Warehouse Coordinator,dxpw-coordinator-read-only,Warehouse CoordinatorReadonly"
  },
  sales: {
    url: "https://stagingapi.nx.nutrien.com/sales/",
    endpoint: "authorizeUser",
    appCode: "dxp-salesContract",
    approvedUserGroups:
      "dxp-salesContract-user,dxp-salesManager-group,dxp-salesDirector-group"
  },
  railcar: {
    url: "https://stagingapi.nx.nutrien.com/railfleetmanagement/",
    endpoint: "authorizeUser",
    appCode: "dxp-salesContract",
    approvedUserGroups: "dxp-rfm-admin,dxp-rfm-analyst,dxp-rfm-viewer"
  }
};

const uatAuthConfig: any = {
  awsRegion: "us-east-2",
  cognitoRegion: "us-east-2",
  userPoolWebClientId: "1e1a6q1qrp20oms8psmg5q39ec",
  domain:
    "preprod-experience-platform-nutrien.auth.us-east-2.amazoncognito.com",
  appUserGroupName: "dxp-orchestrator-user",
  scope: [
    "phone",
    "email",
    "profile",
    "openid",
    "aws.cognito.signin.user.admin"
  ],
  redirectSignIn: "https://uat.nx.nutrien.com/",
  redirectSignOut: "https://uat.nx.nutrien.com/",
  userPoolId: "us-east-2_cupM6yNkc",
  appClientId: "1e1a6q1qrp20oms8psmg5q39ec",
  loglevel: "DEBUG",
  authEndpoint: "authorizeWarehouseUser",
  cookieExpiration: 7,
  warehouse: {
    url: "https://uatapi.nx.nutrien.com/warehouse/",
    endpoint: "authorizeWarehouseUser",
    appCode: "dxp-warehouse",
    approvedUserGroups:
      "dxpw-admin,Warehouse Administrator,dxpw-standard,Warehouse Operator,dxpw-standard-read-only,Warehouse OperatorReadonly,Warehouse OperatorCanReceive,dxpw-coordinator,Warehouse Coordinator,dxpw-coordinator-read-only,Warehouse CoordinatorReadonly"
  },
  sales: {
    url: "https://uatapi.nx.nutrien.com/sales/",
    endpoint: "authorizeUser",
    appCode: "dxp-salesContract",
    approvedUserGroups:
      "dxp-salesContract-user,dxp-salesManager-group,dxp-salesDirector-group"
  },
  railcar: {
    url: "https://uatapi.nx.nutrien.com/railfleetmanagement/",
    endpoint: "authorizeUser",
    appCode: "dxp-salesContract",
    approvedUserGroups: "dxp-rfm-admin,dxp-rfm-analyst,dxp-rfm-viewer"
  }
};

const prodAuthConfig: any = {
  awsRegion: "us-east-2",
  cognitoRegion: "us-east-2",
  userPoolWebClientId: "2m4kp84vvb0filot0o12hqtn0",
  domain: "experience-platform-nutrien.auth.us-east-2.amazoncognito.com",
  appUserGroupName: "dxp-orchestrator-user",
  scope: [
    "phone",
    "email",
    "profile",
    "openid",
    "aws.cognito.signin.user.admin"
  ],
  redirectSignIn: "https://nx.nutrien.com/",
  redirectSignOut: "https://nx.nutrien.com/",
  userPoolId: "us-east-2_XEaLQx09U",
  appClientId: "2m4kp84vvb0filot0o12hqtn0",
  loglevel: "DEBUG",
  authEndpoint: "authorizeWarehouseUser",
  cookieExpiration: 7,
  warehouse: {
    url: "https://api.nx.nutrien.com/warehouse/",
    endpoint: "authorizeWarehouseUser",
    appCode: "dxp-warehouse",
    approvedUserGroups:
      "dxpw-admin,Warehouse Administrator,dxpw-standard,Warehouse Operator,dxpw-standard-read-only,Warehouse OperatorReadonly,Warehouse OperatorCanReceive,dxpw-coordinator,Warehouse Coordinator,dxpw-coordinator-read-only,Warehouse CoordinatorReadonly"
  },
  sales: {
    url: "https://api.nx.nutrien.com/sales/",
    endpoint: "authorizeUser",
    appCode: "dxp-salesContract",
    approvedUserGroups:
      "dxp-salesContract-user,dxp-salesManager-group,dxp-salesDirector-group"
  },
  railcar: {
    url: "https://api.nx.nutrien.com/railfleetmanagement/",
    endpoint: "authorizeUser",
    appCode: "dxp-railfleet-management",
    approvedUserGroups: "dxp-rfm-admin,dxp-rfm-analyst,dxp-rfm-viewer"
  }
};

const localRequestAccessConfig: any = {
  rootUrl: "https://devapi.nx.nutrien.com/user-management",
  apiKey: "DdJliiq4Kca5rinPDccti7lC7SjYuOQG51oUmY5a"
};

const devRequestAccessConfig: any = {
  rootUrl: "https://devapi.nx.nutrien.com/user-management",
  apiKey: "DdJliiq4Kca5rinPDccti7lC7SjYuOQG51oUmY5a"
};

const stagingRequestAccessConfig: any = {
  rootUrl: "https://stagingapi.nx.nutrien.com/user-management",
  apiKey: "GIiit2k6e64H4ro75qU7f26kFFeWuIVB7OS69h6B"
};

const uatRequestAccessConfig: any = {
  rootUrl: "https://uatapi.nx.nutrien.com/user-management",
  apiKey: "fiPUpqmTrMrX6nIJxrzi46EQnJmbmR59dFcwcp6j"
};

const prodRequestAccessConfig: any = {
  rootUrl: "https://api.nx.nutrien.com/user-management",
  apiKey: "vpwkQqocA19RRYm3FTqj35KX7Gguelf48Nu3GEvj"
};

const local = {
  auth: localAuthConfig,
  requestAccessConfig: localRequestAccessConfig,
  cxpAPIUrl: "https://devapi.nutrienexperience.com",
  dxpAPIUrl: "https://devapi.nx.nutrien.com/railfleetmanagement",
  configCatKey: "D-HZCLI1-0qLkf5cLucb8Q/Vik7LXNTeEyrVgLtOQuhlg",
  googleAnalyticsID: "UA-141922109-1"
};

const dev = {
  auth: devAuthConfig,
  requestAccessConfig: devRequestAccessConfig,
  cxpAPIUrl: "https://devapi.nutrienexperience.com",
  dxpAPIUrl: "https://devapi.nx.nutrien.com/railfleetmanagement",
  configCatKey: "D-HZCLI1-0qLkf5cLucb8Q/Vik7LXNTeEyrVgLtOQuhlg",
  googleAnalyticsID: "UA-141922109-1"
};

const staging = {
  auth: stagingAuthConfig,
  requestAccessConfig: stagingRequestAccessConfig,
  cxpAPIUrl: "https://stagingapi.nutrienexperience.com",
  dxpAPIUrl: "https://stagingapi.nx.nutrien.com/railfleetmanagement",
  configCatKey: "D-HZCLI1-0qLkf5cLucb8Q/SH3LOEzDj0i1jK4ymY88yQ",
  googleAnalyticsID: "UA-141922109-12"
};

const uat = {
  auth: uatAuthConfig,
  requestAccessConfig: uatRequestAccessConfig,
  cxpAPIUrl: "https://uatapi.nutrienexperience.com",
  dxpAPIUrl: "https://uatapi.nx.nutrien.com/railfleetmanagement",
  configCatKey: "D-HZCLI1-0qLkf5cLucb8Q/mX8yj-6s6USoxs-b1gbe-Q",
  googleAnalyticsID: "UA-141922109-12"
};

const prod = {
  auth: prodAuthConfig,
  requestAccessConfig: prodRequestAccessConfig,
  cxpAPIUrl: "https://api.nutrienexperience.com",
  dxpAPIUrl: "https://api.nx.nutrien.com/railfleetmanagement",
  configCatKey: "D-HZCLI1-0qLkf5cLucb8Q/PItORgInq0mEWOK41VCKtA",
  googleAnalyticsID: "UA-141922109-13"
};

interface IConfig {
  [key: string]: any;
  local: any;
  dev: any;
  staging: any;
  uat: any;
  prod: any;
}

let config: IConfig = {
  env: env,
  local: local,
  dev: dev,
  staging: staging,
  uat: uat,
  prod: prod
};

export default config[config.env ? config.env : "local"];
